var exports = {};

/*! https://mths.be/utf8js v3.0.0 by @mathias */
;

(function (root) {
  var stringFromCharCode = String.fromCharCode; // Taken from https://mths.be/punycode

  function ucs2decode(string) {
    var output = [];
    var counter = 0;
    var length = string.length;
    var value;
    var extra;

    while (counter < length) {
      value = string.charCodeAt(counter++);

      if (value >= 55296 && value <= 56319 && counter < length) {
        // high surrogate, and there is a next character
        extra = string.charCodeAt(counter++);

        if ((extra & 64512) == 56320) {
          // low surrogate
          output.push(((value & 1023) << 10) + (extra & 1023) + 65536);
        } else {
          // unmatched surrogate; only append this code unit, in case the next
          // code unit is the high surrogate of a surrogate pair
          output.push(value);
          counter--;
        }
      } else {
        output.push(value);
      }
    }

    return output;
  } // Taken from https://mths.be/punycode


  function ucs2encode(array) {
    var length = array.length;
    var index = -1;
    var value;
    var output = "";

    while (++index < length) {
      value = array[index];

      if (value > 65535) {
        value -= 65536;
        output += stringFromCharCode(value >>> 10 & 1023 | 55296);
        value = 56320 | value & 1023;
      }

      output += stringFromCharCode(value);
    }

    return output;
  }

  function checkScalarValue(codePoint) {
    if (codePoint >= 55296 && codePoint <= 57343) {
      throw Error("Lone surrogate U+" + codePoint.toString(16).toUpperCase() + " is not a scalar value");
    }
  }
  /*--------------------------------------------------------------------------*/


  function createByte(codePoint, shift) {
    return stringFromCharCode(codePoint >> shift & 63 | 128);
  }

  function encodeCodePoint(codePoint) {
    if ((codePoint & 4294967168) == 0) {
      // 1-byte sequence
      return stringFromCharCode(codePoint);
    }

    var symbol = "";

    if ((codePoint & 4294965248) == 0) {
      // 2-byte sequence
      symbol = stringFromCharCode(codePoint >> 6 & 31 | 192);
    } else if ((codePoint & 4294901760) == 0) {
      // 3-byte sequence
      checkScalarValue(codePoint);
      symbol = stringFromCharCode(codePoint >> 12 & 15 | 224);
      symbol += createByte(codePoint, 6);
    } else if ((codePoint & 4292870144) == 0) {
      // 4-byte sequence
      symbol = stringFromCharCode(codePoint >> 18 & 7 | 240);
      symbol += createByte(codePoint, 12);
      symbol += createByte(codePoint, 6);
    }

    symbol += stringFromCharCode(codePoint & 63 | 128);
    return symbol;
  }

  function utf8encode(string) {
    var codePoints = ucs2decode(string);
    var length = codePoints.length;
    var index = -1;
    var codePoint;
    var byteString = "";

    while (++index < length) {
      codePoint = codePoints[index];
      byteString += encodeCodePoint(codePoint);
    }

    return byteString;
  }
  /*--------------------------------------------------------------------------*/


  function readContinuationByte() {
    if (byteIndex >= byteCount) {
      throw Error("Invalid byte index");
    }

    var continuationByte = byteArray[byteIndex] & 255;
    byteIndex++;

    if ((continuationByte & 192) == 128) {
      return continuationByte & 63;
    } // If we end up here, it’s not a continuation byte


    throw Error("Invalid continuation byte");
  }

  function decodeSymbol() {
    var byte1;
    var byte2;
    var byte3;
    var byte4;
    var codePoint;

    if (byteIndex > byteCount) {
      throw Error("Invalid byte index");
    }

    if (byteIndex == byteCount) {
      return false;
    } // Read first byte


    byte1 = byteArray[byteIndex] & 255;
    byteIndex++; // 1-byte sequence (no continuation bytes)

    if ((byte1 & 128) == 0) {
      return byte1;
    } // 2-byte sequence


    if ((byte1 & 224) == 192) {
      byte2 = readContinuationByte();
      codePoint = (byte1 & 31) << 6 | byte2;

      if (codePoint >= 128) {
        return codePoint;
      } else {
        throw Error("Invalid continuation byte");
      }
    } // 3-byte sequence (may include unpaired surrogates)


    if ((byte1 & 240) == 224) {
      byte2 = readContinuationByte();
      byte3 = readContinuationByte();
      codePoint = (byte1 & 15) << 12 | byte2 << 6 | byte3;

      if (codePoint >= 2048) {
        checkScalarValue(codePoint);
        return codePoint;
      } else {
        throw Error("Invalid continuation byte");
      }
    } // 4-byte sequence


    if ((byte1 & 248) == 240) {
      byte2 = readContinuationByte();
      byte3 = readContinuationByte();
      byte4 = readContinuationByte();
      codePoint = (byte1 & 7) << 18 | byte2 << 12 | byte3 << 6 | byte4;

      if (codePoint >= 65536 && codePoint <= 1114111) {
        return codePoint;
      }
    }

    throw Error("Invalid UTF-8 detected");
  }

  var byteArray;
  var byteCount;
  var byteIndex;

  function utf8decode(byteString) {
    byteArray = ucs2decode(byteString);
    byteCount = byteArray.length;
    byteIndex = 0;
    var codePoints = [];
    var tmp;

    while ((tmp = decodeSymbol()) !== false) {
      codePoints.push(tmp);
    }

    return ucs2encode(codePoints);
  }
  /*--------------------------------------------------------------------------*/


  root.version = "3.0.0";
  root.encode = utf8encode;
  root.decode = utf8decode;
})(exports);

export default exports;